import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh needs-renewal`}</strong>{` -- Check if an SSH certificate needs to be renewed`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh needs-renewal <crt-file>
[--expires-in=<percent|duration>] [--verbose]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh needs-renewal`}</strong>{` returns '0' if the SSH certificate needs
to be renewed based on it's remaining lifetime. Returns '1' if the SSH
certificate is within it's validity lifetime bounds and does not need to be
renewed. By default, an SSH certificate "needs renewal" when it has
passed 66% (default threshold) of it's allotted lifetime. This threshold can be
adjusted using the '--expires-in' flag.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`cert-file`}</inlineCode>{`
The path to an SSH certificate.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--expires-in`}</strong>{`=`}<inlineCode parentName="p">{`percent|duration`}</inlineCode>{`
Check if the certificate expires within the given time window
using `}<inlineCode parentName="p">{`percent|duration`}</inlineCode>{`. If using `}<inlineCode parentName="p">{`percent`}</inlineCode>{`, the input must be followed by a "%"
character. If using `}<inlineCode parentName="p">{`duration`}</inlineCode>{`, the input must be a sequence of decimal numbers,
each with optional fraction and a unit suffix, such as "300ms", "-1.5h" or "2h45m".
Valid time units are "ns", "us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--verbose`}</strong>{`, `}<strong parentName="p">{`-v`}</strong>{`
Print human readable affirmation if certificate requires renewal.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns '0' if the SSH certificate needs renewal, '1' if the
SSH certificate does not need renewal, '2' if the SSH certificate file does not
exist, and '255' for any other error.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Check if an SSH certificate needs renewal using the default threshold (66%):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh needs-renewal ./ssh_host_ed25519_key.pub
`}</code></pre>
    <p>{`Check if certificate will expire within a given duration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh needs-renewal ./ssh_host_ed25519_key.pub --expires-in 1h15m
`}</code></pre>
    <p>{`Check if an SSH certificate has passed 75 percent of it's lifetime:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal ./ssh_host_ed25519_key.pub --expires-in 75%
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      